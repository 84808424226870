import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { TextFieldStyles } from "./styles";
import { Button } from "@material-ui/core";
import Axios from "axios";
import { getRhodeIslandTSRFRebate, getSystemSize } from "./Calculations";
import { client } from "../../App";
import { ALL_PRODUCTS_QUERY } from "../../utils/ProductsQueries";
import { Context } from "../../Context/ProposalContext";

function GetEminemData({ setOtherValues, initialFormValues }) {
  const { otherValues } = useContext(Context);
  const [eminemId, setEminemId] = useState(
    otherValues?.eminemId?.replaceAll(/\D+/g, "") ?? " "
  );
  const [error, setError] = useState("");
  const [fetchStatus, setFetchStatus] = useState("");
  const [eminemData, setEminemData] = useState();

  const {
    allProducts: { allPanels },
  } = client.readQuery({
    query: ALL_PRODUCTS_QUERY,
  });

  const handleNoDataFound = (id) => {
    setOtherValues((prev) => ({
      ...prev,
      eminemId: null,
    }));
  };

  useEffect(() => {
    if (otherValues.eminemId)
      handleSubmit(otherValues?.eminemId?.replaceAll(/\D+/g, ""));
  }, []);

  const handleSubmit = async (eminemId) => {
    setError("");
    setFetchStatus("");

    try {
      const { data } = await Axios.get(
        "https://api.momentumsolar.io/internal/eminem/get_values/" + eminemId
      );
      setFetchStatus("success");

      if (data) {
        setEminemData(data);
        setOtherValues((prev) =>
          parseEminemToOtherValues(
            prev,
            data,
            initialFormValues,
            allPanels,
            eminemId
          )
        );
        return;
      }
      handleNoDataFound(eminemId);
    } catch (error) {
      console.error(error);
      handleNoDataFound(eminemId);
      setError(error.message);
      setEminemData();
      setFetchStatus("error");
    }
  };

  return (
    <Wrapper>
      <div className="input-wrapper">
        <TextFieldStyles
          label="Eminem Id"
          name={"eminem_id"}
          type="tel"
          value={eminemId}
          pattern="[0-9]"
          // min="0"
          // step="1"
          onChange={(e) => {
            const res = setEminemId(
              e?.target?.value?.replaceAll(/\D+/g, "") || ""
            );
            console.log(e.target.value, res);
            return res;
          }}
          placeholder="Eminem Id"
          error={!eminemId}
          required
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              eminemId && handleSubmit(eminemId);
            }
          }}
        />
        <Button
          disabled={!eminemId}
          variant="contained"
          onClick={() => handleSubmit(eminemId)}
        >
          Load Eminem Data
        </Button>
      </div>
      {fetchStatus === "success" && eminemData && (
        <p
          className="message-text"
          style={{ color: "black", fontSize: "14px" }}
        >
          Eminem data loaded successfully
        </p>
      )}
      {fetchStatus === "success" && !eminemData && (
        <p
          className="message-text"
          style={{ color: "black", fontSize: "14px" }}
        >
          No Eminem data found
        </p>
      )}
      {fetchStatus === "loading" && (
        <p className="message-text">Fetching data from Eminem...</p>
      )}
      {error && (
        <div className="error-wrapper">
          <p>Something went wrong fetching data from Eminem - </p>
          <p>{error}</p>
        </div>
      )}
    </Wrapper>
  );
}

const parseEminemToOtherValues = (
  otherValues,
  eminemData,
  initialFormValues,
  allPanels,
  eminemId
) => {
  console.log({ eminemData });
  const result = {
    ...otherValues,
    tsrf: +eminemData?.weighted_tsrf || null,
    panels: eminemData?.panel_count || null,
    production: eminemData?.total_production || null,
    monthly_production: eminemData?.monthly_production || [],
    shade_percent: +eminemData?.shade_percent || "",
    eminemId,
  };

  if (result.panels) {
    result.size = getSystemSize(result, allPanels);
  }

  if (initialFormValues.State === "RI") {
    result.rebate = getRhodeIslandTSRFRebate({
      ...otherValues,
      ...initialFormValues,
      ...result,
    });
  }
  return result;
};

export default GetEminemData;

const Wrapper = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 2em;
  margin-bottom: 2em;

  .input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;

    button {
      background-color: #001649;
      color: white;
    }

    button:disabled,
    button[disabled] {
      background-color: #cccccc;
      color: #666666;
    }

    .MuiFormControl-root {
      padding-bottom: 8px;
    }
  }

  .error-wrapper {
    margin: 8px auto;
    font-size: 12px;
    color: red;
    max-width: 600px;

    p {
      display: inline;
      margin: 2px;
    }
  }

  .message-text {
    font-size: 12px;
    margin: auto;
    color: grey;
  }
`;
