import React from "react";
import {
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import { Wrapper } from "../components/ProposalTable/ProposalTable";

import AddProduct from "./AddProduct";
import EditDel from "./EditDel";

const ProductTable = (props) => {
  const { productData, activeProducts } = props;

  const tableHeaders = [
    { label: "Name", key: "name" },
    { label: "P Max", key: "pmax" },
    { label: "Brand", key: "brand" },
    { label: "Model", key: "model" },
    { label: "Active", key: "active" },
    { label: "Panel Img" },
    { label: "Add / Del", key: "delete" },
  ];

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [page, setPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //const tableData = adminFilters(users.users, search, currSort, currSearch);

  return (
    <Wrapper>
      <Paper className="root">
        <div className="tableWrapper">
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((column) => (
                  <TableCell
                    key={column.label}
                    style={{
                      background: "black",
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    <div className="headerSort">{column.label}</div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {productData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow key={row.name}>
                      <EditDel product={row} activeProducts={activeProducts} />
                    </TableRow>
                    // <TableRow key={row.name}>
                    //   {tableHeaders.map(col => {
                    //     if (col.key !== "delete")
                    //       return (
                    //         <TableCell key={row[col.key]}>
                    //           {row[col.key]}
                    //         </TableCell>
                    //       );
                    //     else {
                    //       return (
                    //         <DeleteProduct
                    //           activeProducts={activeProducts}
                    //           key={col.key}
                    //           _id={row._id}
                    //         />
                    //       );
                    //     }
                    //   })}
                    // </TableRow>
                  );
                })}
              <AddProduct activeProducts={activeProducts} />
            </TableBody>
          </Table>
        </div>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          // sActionsComponent={CustomPagination}
          count={productData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Wrapper>
  );
};

export default ProductTable;
