import React, { useContext, useState, useRef } from "react";
import {
  MenuItem,
  InputLabel,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
//import FileDrop from "react-file-drop";
import {
  FormControlStyles,
  TextFieldStyles,
  SelectStyles,
  ConsumptionTypeCon,
} from "../../Refactored/StartProposalV2/styles";

import ConsumptionType from "../../components/ConsumptionType/ConsumptionType";
import styled from "styled-components";
import { Context } from "../../Context/ProposalContext";
import {
  getLoanAmount,
  getRhodeIslandTSRFRebate,
  getSolarPayment,
  getSystemSize,
  suggestedBillSwapPPW,
} from "../../Refactored/StartProposalV2/Calculations";
import { uploadImg } from "../../utils/API";
import validator from "validator";
import moment from "moment";
import { useEffect } from "react";
import GeneracForm from "../GeneracForm/GeneracForm";
import { Generacs } from "../../Refactored/StartProposalV2/StartProposalV2";
import DecoTechForm from "../DecoTechForm/DecoTechForm";
import { client } from "../../App";
import { ALL_PRODUCTS_QUERY } from "../../utils/ProductsQueries";
import GetEminemData from "../../Refactored/StartProposalV2/GetEminemData";

const proposalReasons = [
  //`BECAUSE`,
  `Loan States - P1`,
  `Loan States - P2`,
  `Initial Proposal `,
  `Revision – Incorrect Setbacks `,
  `Revision – Design Change Requested`,
  `Revision – Price Drop `,
  `Revision – Initial Proposal On Wrong Property `,
  `Revision – Update Shade Modeling `,
  `Revision – Other  `,
  `Financer Pivot – Credit Fail `,
  `Financer Pivot – Property Type DQ `,
  `Financer Pivot – Rep/Manager Requested `,
  `Financer Pivot – Other`,
  "Revision - 10% Letter",
];

const proposalSubreason = [
  "Panel wattage upgrade",
  "Bill not provided for original proposal - adjust design based on rep/customer input",
  "Add usage/subtract usage for people moving out/in",
  "Base system off monthly average provided",
  "Decrease offset",
  "Increase offset",
  "Increase offset over 100%",
  "Customer adding EV",
  "Customer adding pool",
  "Add another meter",
  "Customer building another structure",
  "Add garage to design",
  "Redesign as a ground mount",
  "Include Tree removal",
  "Propose without tree removal",
  "Remove panels from front of house",
  "Aesthetic change in layout",
  "Remove obstructions",
  "Remove pool panels",
  "Match competitor's design",
  "Disregard setbacks",
  "HOA restrictions",
  "Wrong home built for initial proposal",
  "Add battery",
  "Remove battery",
  "Change battery size",
];

export const InputStyles = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "45%")};
  flex-direction: ${(props) => (props.flexdir ? props.flexdir : "row")};
  justify-content: space-around;
  align-items: center;

  .label-styles {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 150px;
  }
  label {
    /* display: block; */
    margin-bottom: 2rem;
  }
  input,
  textarea,
  select {
    width: 70%;
    padding: 0.2rem;
    font-size: 1rem;
    border: 1px solid black;
    display: flex;
    &:focus {
      outline: 0;
      border-color: #3db0ef;
    }
  }
`;

// const StyledDrop = styled(FileDrop)`
//   display: flex;
//   width: 100%;

//   .file-drop-dragging-over-frame {
//     background: lightblue;
//     opacity: 0.5;
//   }
// `;
const NonUniques = () => {
  const {
    initialFormValues,
    otherValues,
    setOtherValues,
    setMonthVals,
    useOriginal,
    setUseOriginal,
    revision,
    setFID,
    fID,
    consumptionType,
    setConsumptionType,
    austinEnergy,
  } = useContext(Context);

  const { proposalType } = otherValues;

  const {
    allProducts: { allPanels },
  } = client.readQuery({
    query: ALL_PRODUCTS_QUERY,
  });

  useEffect(() => {
    if (revision) {
      let otherValsCopy = {
        ...otherValues,
      };
      otherValsCopy.proposalReason = "Revision - 10% Letter";
      otherValsCopy.additionalwork[0].required = false;
      otherValsCopy.additionalwork[1].required = false;

      setOtherValues(otherValsCopy);

      setUseOriginal(false);
    }
    if (!otherValues.designUrl) {
      setUseOriginal(false);
    }
    // eslint-disable-next-line
  }, [revision, setUseOriginal, setOtherValues]);

  const originalFile = useRef(otherValues.designUrl);

  const [filePreview, setFilePreview] = useState("");
  const [uploadedFile, setUploadedFile] = useState({});

  const [error, setErr] = useState({
    panels: true,
    production: true,
    ppW: true,
  });

  const changeInput = (e, validationOption, options) => {
    e.persist();

    if (validationOption) {
      if (!validator[validationOption](e.target.value, options)) {
        return;
      }
    }
    setErr({
      ...error,
      [e.target.name]: false,
    });
    console.log("changing", e);
    setOtherValues((prev) => {
      // prev[e.target.name] = e.target.value
      const newVals = {
        ...prev,
        [e.target.name]: e.target.value,
      };
      newVals.loanAmount = getLoanAmount(
        newVals,
        initialFormValues,
        austinEnergy
      );
      newVals.loanPayment = getSolarPayment(
        newVals,
        initialFormValues,
        austinEnergy
      );
      console.log(newVals);
      return newVals;
    });
  };

  const msesPrimeHandler = (e) => {
    setOtherValues({ ...otherValues, msesPrime: e.target.value });
  };

  const handleDropChange = (e) => {
    setOtherValues({
      ...otherValues,
      [e.target.name]: e.target.value,
      consumption: 0,
    });

    setMonthVals([
      { month: "Jan", days: 31, consumption: "", totalCost: 0 },
      { month: "Feb", days: 28, consumption: "", totalCost: 0 },
      { month: "March", days: 31, consumption: "", totalCost: 0 },
      { month: "April", days: 30, consumption: "", totalCost: 0 },
      { month: "May", days: 31, consumption: "", totalCost: 0 },
      { month: "June", days: 30, consumption: "", totalCost: 0 },
      { month: "July", days: 31, consumption: "", totalCost: 0 },
      { month: "August", days: 31, consumption: "", totalCost: 0 },
      { month: "Sept", days: 30, consumption: "", totalCost: 0 },
      { month: "Oct", days: 31, consumption: "", totalCost: 0 },
      { month: "Nov", days: 30, consumption: "", totalCost: 0 },
      { month: "Dec", days: 31, consumption: "", totalCost: 0 },
    ]);
  };

  const handleUpload = async (e) => {
    const selectedFile = document.getElementById("upload").files[0];
    const name = e.target.name;
    let reader = new FileReader();
    reader.onloadend = () => {
      setFilePreview(reader.result);
    };

    const designUrl = await uploadImg(
      selectedFile,
      `${initialFormValues.oppnum.trim()}/designImage-${moment().format(
        "MM-DD-YYYY-hh:mm:ss-a"
      )}`
    );
    reader.readAsDataURL(selectedFile);

    setUploadedFile({
      designUrl,
      [name]: selectedFile,
    });

    setOtherValues({
      ...otherValues,
      designUrl,
      [name]: selectedFile,
    });
  };

  // const utilityChange = (e, n) => {
  //   //has value ie a user picks a utility
  //   if (n) {
  //     console.log("here is util");
  //     console.log(n);
  //     setUtilitySubmit(n);
  //   } else {
  //     console.log("hit x");
  //     setUtilitySubmit({});
  //   }
  // };
  return (
    <>
      <GetEminemData
        setOtherValues={setOtherValues}
        initialFormValues={initialFormValues}
      />
      <FormControlStyles>
        <InputLabel> Proposal Reason </InputLabel>
        <SelectStyles
          value={otherValues.proposalReason}
          onChange={(e) => changeInput(e)}
          name={"proposalReason"}
        >
          {proposalReasons.map((reason) => (
            <MenuItem key={reason} value={reason}>
              {reason}
            </MenuItem>
          ))}
        </SelectStyles>
      </FormControlStyles>
      {otherValues.proposalReason.includes("Design Change Requested") && (
        <FormControlStyles>
          <InputLabel> Proposal SubReason </InputLabel>
          <SelectStyles
            value={otherValues.proposalSubreason}
            onChange={(e) => changeInput(e)}
            name={"proposalSubreason"}
          >
            {proposalSubreason.map((reason) => (
              <MenuItem key={reason} value={reason}>
                {reason}
              </MenuItem>
            ))}
          </SelectStyles>
        </FormControlStyles>
      )}
      <TextFieldStyles
        type="number"
        name={"panels"}
        value={otherValues.panels || ""}
        onChange={(e) => {
          const { value: panels } = e?.target;
          setOtherValues((prev) => {
            const newObj = { ...prev, panels };
            newObj.size = getSystemSize(newObj, allPanels);
            if (
              +otherValues?.tsrf >= 80 &&
              initialFormValues.State === "RI" &&
              otherValues.size
            )
              newObj.rebate = getRhodeIslandTSRFRebate({
                ...newObj,
                ...initialFormValues,
              });

            newObj.loanAmount = getLoanAmount(newObj, initialFormValues);
            return newObj;
          });
          // changeInput(e, "isNumeric", { no_symbols: true });
        }}
        label={"Panel Quantity"}
        autoComplete="off"
        error={false}
      />
      <TextFieldStyles
        name={"production"}
        value={otherValues.production}
        onChange={(e) => changeInput(e, "isNumeric", { no_symbols: true })}
        label={"production"}
        autoComplete="off"
        type={"number"}
        // error={!values[key] ? true : false}
      />
      {/* {otherValues.proposalType !== "IGSPPA" && ( */}
      <TextFieldStyles
        name={"ppW"}
        value={otherValues.ppW}
        onChange={(e) => changeInput(e)}
        label={"PPW"}
        type={"number"}
        autoComplete="off"
        error={!otherValues.ppW ? true : false}
        helperText={suggestedBillSwapPPW(initialFormValues, otherValues)}
      />
      {/* )} */}
      {
        // despite name of file uniq to loan
      }
      <ConsumptionTypeCon>
        <FormControlStyles>
          <InputLabel> Consumption Type </InputLabel>
          <SelectStyles
            value={consumptionType}
            onChange={(e) => setConsumptionType(e.target.value)}
            name={"consumptionType"}
          >
            {/* <MenuItem value={"Daily"}>Daily</MenuItem> */}
            <MenuItem value={"Monthly"}> Monthly</MenuItem>
            <MenuItem value={"Annual"}>Annual</MenuItem>
          </SelectStyles>
        </FormControlStyles>

        <ConsumptionType />
      </ConsumptionTypeCon>
      <TextFieldStyles
        name={"utilityRate"}
        value={otherValues.utilityRate}
        onChange={(e) => changeInput(e)}
        label={"Utility Rate"}
        autoComplete="off"
        type={"number"}
      />
      <div
        style={{
          display: "flex",
          minWidth: "45%",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <TextFieldStyles
          name="tsrf"
          value={+otherValues.tsrf ?? ""}
          key="tsrf"
          onChange={(e) => {
            //Rebate automatically calculated and applied if tsrf is >= 80
            e.persist();
            const tsrf = e?.target?.value.replaceAll(/\D+/g, "") ?? null;
            setOtherValues((prev) => {
              const rebate = getRhodeIslandTSRFRebate({
                ...otherValues,
                ...initialFormValues,
                tsrf,
              });
              return { ...prev, rebate, tsrf };
            });
          }}
          label={"TSRF (0-100)"}
          // type="number"
          style={{
            flexGrow: 1,
          }}
          error={
            otherValues.tsrf
              ? +otherValues.tsrf <= 100 && +otherValues.tsrf >= 0
                ? false
                : true
              : true
          }
        />
        {initialFormValues.State === "RI" &&
          otherValues?.rebate > 0 &&
          !initialFormValues.proposalType?.includes("IGS") &&
          !initialFormValues.proposalType?.includes("MSES") && (
            <p style={{ fontSize: 16, whiteSpace: "nowrap" }}>
              Rebate: ${otherValues.rebate}
            </p>
          )}
      </div>
      {(initialFormValues.State === "CT" ||
        initialFormValues.State === "RI") && (
        <TextFieldStyles
          name={"shade_percent"}
          value={+otherValues.shade_percent}
          key={"shade_percent"}
          onChange={(e) => {
            e.persist();
            const shade_percent =
              e?.target?.value.replaceAll(/\D+/g, "") ?? null;

            setOtherValues((prev) => ({ ...prev, shade_percent }));
          }}
          label={"Solar Access % (0-100)"}
          required
          error={
            otherValues.shade_percent
              ? +otherValues.shade_percent < 100 &&
                +otherValues.shade_percent >= 0
                ? false
                : true
              : true
          }
        />
      )}
      {otherValues.proposalType !== "Cash" && (
        <TextFieldStyles
          name={"FINANCERID"}
          value={fID}
          key={"FINANCERID"}
          onChange={(e) => setFID(e.currentTarget.value)}
          id={`filled-FID`}
          label={"Financer ID"}
          autoComplete="off"
        />
      )}
      {otherValues.proposalType === "MSES" && (
        <div style={{ display: "flex", minWidth: "45%" }}>
          <FormControlStyles>
            <InputLabel> MSES Prime </InputLabel>
            <SelectStyles
              value={otherValues.msesPrime}
              onChange={msesPrimeHandler}
              name={"msesPrime"}
            >
              <MenuItem value={true}>Yes</MenuItem>

              <MenuItem value={false}>No</MenuItem>
            </SelectStyles>
          </FormControlStyles>
        </div>
      )}
      {Generacs.includes(proposalType) && (
        <GeneracForm proposalType={proposalType} />
      )}
      {(otherValues.proposalType === "DecoCash" ||
        otherValues.proposalType === "DecoLoan") && (
        <DecoTechForm proposalType={proposalType} />
      )}
      <InputStyles>
        <div className="inputs">
          {!revision && originalFile.current !== "" && (
            <FormControlLabel
              control={
                <Switch
                  checked={useOriginal}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!useOriginal) {
                      setOtherValues((prevState) => ({
                        ...prevState,
                        designUrl: originalFile.current,
                        designImageUpload: "",
                      }));
                    }
                    if (useOriginal) {
                      if (uploadedFile) {
                        setOtherValues((prevState) => ({
                          ...prevState,
                          designUrl: uploadedFile.designUrl,
                          designImageUpload: uploadedFile.designImageUpload,
                        }));
                      } else {
                        setOtherValues((prevState) => ({
                          ...prevState,
                          designUrl: "",
                          designImageUpload: "",
                        }));
                      }
                    }

                    setUseOriginal(!useOriginal);
                  }}
                />
              }
              label={
                useOriginal ? "Using Previous Image" : "Using Uploaded Image"
              }
            />
          )}

          {/* <StyledDrop onDrop={(e) => handleDrop(e)}> */}
          <div className="label-styles">
            <label htmlFor="upload">
              Upload Design Image
              <input
                onChange={(e) => handleUpload(e)}
                type="file"
                name="designImageUpload"
                id="upload"
                accept="image/*"
                disabled={useOriginal && originalFile.current !== ""}
              />
            </label>
          </div>
          {/* </StyledDrop> */}
        </div>
        {filePreview && !useOriginal && <img alt="upload" src={filePreview} />}
        {originalFile.current && useOriginal && (
          <img alt="upload" src={originalFile.current} />
        )}
      </InputStyles>
    </>
  );
};

export default NonUniques;
