import React, { useContext } from "react";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import {
  TextFieldStyles,
  NonInputStyles,
} from "../../Refactored/StartProposalV2/styles";
import { Context } from "../../Context/ProposalContext";
import {
  ANNUAL_CONSUMPTION_CURVE,
  getTotalConsumption,
} from "../../Refactored/StartProposalV2/Calculations";

const StyledMonthly = styled(TextField)`
  && {
    width: 31%;
    min-width: 31%;
    margin-right: 1%;
  }
`;

const StyledMonthsContainer = styled.div`
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
`;

export const Monthly = () => {
  const {
    otherValues,
    monthValues,
    setMonthVals,
    consumptionType,
    setOtherValues,
  } = useContext(Context);

  const handleMonthly = (e) => {
    if (consumptionType === "Monthly") {
      let monthIndex = monthValues.findIndex(
        (findMonth) => findMonth.month === e.target.name
      );

      let monthCopy = [...monthValues];
      monthCopy[monthIndex].consumption = +e.target.value;
      console.log(monthCopy);
      setMonthVals(monthCopy);
      setOtherValues((prev) => ({
        ...prev,
        monthly_consumption: monthValues.map((v) => v.consumption),
        consumption: Math.round(
          getTotalConsumption(otherValues, monthValues, consumptionType)
        ),
      }));
      // was mutating state here, dont
      //return;
    }
  };

  return (
    <StyledMonthsContainer>
      {monthValues.map((month) => {
        return (
          <StyledMonthly
            key={month.month}
            type={"number"}
            name={month.month}
            value={month.consumption}
            label={month.month}
            onChange={(e) => handleMonthly(e)}
          ></StyledMonthly>
        );
      })}
      <NonInputStyles>
        {`Total Consumption -  ${otherValues.consumption}`}
      </NonInputStyles>
    </StyledMonthsContainer>
  );
};
export const Daily = () => {
  const { otherValues, monthValues, setMonthVals, consumptionType } =
    useContext(Context);
  const handleDaily = (e) => {
    let monthIndex = monthValues.findIndex(
      (findMonth) => findMonth.month === e.target.name
    );

    let monthCopy = [...monthValues];
    monthCopy[monthIndex].consumption = e.target.value;

    setMonthVals(monthCopy);
    return;
  };

  return (
    <StyledMonthsContainer>
      {monthValues.map((month) => {
        return (
          <StyledMonthly
            key={month.month}
            type={"number"}
            name={month.month}
            value={month.consumption}
            label={month.month}
            onChange={(e) => handleDaily(e)}
          ></StyledMonthly>
        );
      })}
      <NonInputStyles>
        {`Total Consumption - ${getTotalConsumption(
          otherValues,
          monthValues,
          consumptionType
        )}`}
      </NonInputStyles>
    </StyledMonthsContainer>
  );
};

export const Annual = () => {
  const { otherValues, setOtherValues, monthValues, setMonthVals } =
    useContext(Context);
  const handleInputChange = (e) => {
    e.persist();
    const annualConsumption = e.target.value;
    console.log("SETTING");
    setOtherValues((prev) => ({
      ...prev,
      monthly_consumption: ANNUAL_CONSUMPTION_CURVE.map(
        (v) => v * annualConsumption
      ),
      consumption: annualConsumption,
    }));
    setMonthVals((prev) =>
      prev.map((month, i) => ({
        ...month,
        consumption: annualConsumption * ANNUAL_CONSUMPTION_CURVE[i],
      }))
    );
  };
  return (
    <TextFieldStyles
      type="number"
      name={"consumption"}
      value={otherValues.consumption}
      onChange={(e) => handleInputChange(e)}
      label={"Consumption "}
      autoComplete="off"
      error={!otherValues.consumption}
    />
  );
};
