import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ButtonGroup } from "@material-ui/core";
import { buttonColor, StyledButton } from "../AdminHome/AdminHome";
import Count from "../../components/Count/Count";
import UsersProposals from "../UsersProposals/UsersProposals";
import { AdminHomeStyles } from "../AdminHome/styles";
import AllProposalsView from "../AllProposalsView/AllProposalsView";
import {
  ALL_REQUESTED_PROPOSALS,
  USER_BY_EMAIL_QUERY,
} from "../../utils/GQL/QLQueries";
import OtherLoader from "../../components/OtherLoader/OtherLoader";
import { proposalsUnassigned } from "../Assign/Assign";
import { PanTool } from "@material-ui/icons";

import moment from "moment";
import { Context } from "../../Context/ProposalContext";
import { ASSIGN_PROPOSAL_MUTATION } from "../../utils/QLMutations";
import DropProposal from "../../components/DropProposal/DropProposal";
import { dash_type } from "../../config";

const UserBarStyles =
  dash_type === "SLACK"
    ? styled.div`
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .user-nav {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 30%;
        }

        .real-div {
          width: 20%;

          justify-content: center;
          display: flex;
        }
      `
    : styled.div`
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .user-nav {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 50%;
        }

        .real-div {
          width: 20%;

          justify-content: center;
          display: flex;
        }
      `;

const UserHome = ({ usersProposals }) => {
  const {
    userValues: { userName },
    appPermissions,
  } = useContext(Context);

  const [myProposals, setMyProposal] = useState("UsersProposals");
  const { data, loading } = useQuery(ALL_REQUESTED_PROPOSALS, {
    pollInterval: 10000,
  });

  const userId = appPermissions._id;

  const [assignProposal] = useMutation(ASSIGN_PROPOSAL_MUTATION);

  const onAssignProposal = (id) => {
    assignProposal({
      variables: {
        userId,
        proposalId: id,
      },
      refetchQueries: [
        { query: ALL_REQUESTED_PROPOSALS },
        {
          query: USER_BY_EMAIL_QUERY,
          variables: {
            proposalAgentEmail: userName,
          },
        },
      ],
    });
  };

  const Components = { UsersProposals, AllProposalsView };
  const Tag = Components[myProposals];

  if (loading) return <OtherLoader />;

  const reduceOffices = (data) => {
    const reduction = data.reduce((prev, curr) => {
      const propId = curr.id;
      curr = {
        ...curr,
        Office: curr["Office"] === curr["Zip"] ? curr["State"] : curr["Office"],
        timerequestedpretty: curr.timerequested
          ? moment(curr.timerequested).format("MM-DD  hh:mm:ss a")
          : "No Time Requested",
        timerequnix: curr.timerequested
          ? moment(curr.timerequested).unix()
          : null,
        take: <PanTool onClick={() => onAssignProposal(propId)} />,
        drop: <DropProposal userId={userId} proposal={curr} />,
      };

      prev.push(curr);
      return prev;
    }, []);
    return reduction;
  };

  console.log("here is data");
  console.log(data);

  const allUnassigned = proposalsUnassigned(data.requestedProposals);

  const allUnassignedReduced = reduceOffices(allUnassigned);
  const usersProposalsReduced = reduceOffices(usersProposals);

  return (
    <AdminHomeStyles>
      <UserBarStyles>
        <div className="real-div">
          {/* <GrabProposal allUnassigned={allUnassignedReduced} /> */}
        </div>
        <div className="user-nav">
          <ButtonGroup
            aria-label="outlined primary button group"
            variant="text"
          >
            <StyledButton
              onClick={() => setMyProposal("UsersProposals")}
              // eslint-disable-next-line no-use-before-define
              isselected={buttonColor("UsersProposals", myProposals)}
            >
              <div className="text-count">
                My Proposals
                <Count count={usersProposals.length} />
              </div>
            </StyledButton>
            {dash_type !== "SLACK" && (
              <StyledButton
                onClick={() => setMyProposal("AllProposalsView")}
                // eslint-disable-next-line no-use-before-define
                isselected={buttonColor("AllProposalsView", myProposals)}
              >
                <div className="text-count">
                  All Proposals
                  <Count count={allUnassigned.length} />
                </div>
              </StyledButton>
            )}
          </ButtonGroup>
        </div>
        <div className="false-div-a-fucking-phony-just-to-offset-it-isnt-even-real-a-sham-a-fake-a-liar-at-best"></div>
      </UserBarStyles>
      <Tag
        allRequestedProposals={allUnassignedReduced}
        usersProposals={usersProposalsReduced}
      />
    </AdminHomeStyles>
  );
};

export default UserHome;
