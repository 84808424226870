import React from "react";
import NonUniques from "../NonUniqueInputs/NonUniques";
import { FormItself } from "../../Refactored/StartProposalV2/styles";
import Rebate from "../../components/Rebate";
import { Sunlight, GreenSky, Dividend, EnFin, EFS } from "./Partners";
// import { handleDropChange } from "../../Refactored/StartProposalV2/Helpers";
import NonUniqueStatic from "../NotUniqueStatic/NotUniqueStatic";
import AdditionalWork from "../AdditionalWork.js/AdditionWork";

const Loan = ({ otherValues, setOtherValues, State }) => {
  const Components = {
    Sunlight,
    GreenSky,
    Dividend,
    EnFin,
    EFS,
    EFSBridge: EFS,
  };

  const Tag = Components[otherValues.finnancialPartner];
  return (
    <>
      <FormItself>
        <NonUniques otherValues={otherValues} setOtherValues={setOtherValues} />

        <Rebate
          selectedState={State}
          otherValues={otherValues}
          setOtherValues={setOtherValues}
        />
        <Tag
          // onChangeLoanTerm={handleDropChange}
          otherValues={otherValues}
          setOtherValues={setOtherValues}
        />
      </FormItself>
      <AdditionalWork />
      <NonUniqueStatic />
    </>
  );
};

export default Loan;
