import React from "react";
import ProposalTable from "../../components/ProposalTable/ProposalTable";
import { tableHeaders } from "../Home";
import { dash_type } from "../../config";
const UsersProposals = ({ usersProposals }) => {
  let newTableHeaders =
    dash_type === "SLACK"
      ? [...tableHeaders]
      : [...tableHeaders, { id: "8", label: "Drop Proposal", key: "drop" }];
  return <ProposalTable data={usersProposals} tableHeaders={newTableHeaders} />;
};

export default UsersProposals;
