export function validateOtherValues(values, iValues = {}) {
  console.log(values);
  let otherValuesStatus = true;
  let otherValuesMsg = "";
  if (!values.solarPanel || !values.inverter || values.solarPanel === "") {
    otherValuesStatus = false;
    otherValuesMsg =
      "Inverter & Solar panel are required. Please, take a look above!";
    return { otherValuesStatus, otherValuesMsg };
  }
  if (
    (iValues.State === "NY" ||
      iValues.State === "CT" ||
      iValues.State === "MA") &&
    !iValues.utility
  ) {
    console.log(values);
    otherValuesStatus = false;
    otherValuesMsg = "Utility is required!";
    return { otherValuesStatus, otherValuesMsg };
  }

  if (values.proposalType) {
    if (
      values.proposalType === "Cash" ||
      values.proposalType === "IGSPPA" ||
      values.proposalType === "SUNRUN" ||
      values.proposalType === "IGSLEASE"
    ) {
      if (!values.solarPanel || !values.inverter) {
        otherValuesStatus = false;
        otherValuesMsg =
          "Inverter & Solar panel are required. Please, take a look above!";
      }
    } else if (values.proposalType === "Loan") {
      if (!values.solarPanel || !values.inverter || !values.finnancialPartner) {
        otherValuesStatus = false;
        otherValuesMsg =
          "Inverter, Solar Panel & Financial Partner are required. Please, take a look above!";
      }
    }
  } else {
    otherValuesStatus = false;
    otherValuesMsg = "Proposal Type is required. Please, take a look above!";
  }

  return { otherValuesStatus, otherValuesMsg };
}

export function validateValues(values) {
  let initialValuesStatus = true;
  let initialOtherValuesMsg = "";

  for (let prop in values) {
    if (!values[prop] && prop !== "FinancerId") {
      console.log(prop);
      initialValuesStatus = false;
    }
  }
  initialOtherValuesMsg = !initialValuesStatus
    ? "Form values are missing. Please, take a look above."
    : "";

  return { initialValuesStatus, initialOtherValuesMsg };
}
