import React, { useContext, useState } from "react";
import {
  MenuItem,
  InputLabel,
  FormControlLabel,
  Switch,
  FormControl,
} from "@material-ui/core";
import {
  FormControlStyles,
  SelectStyles,
  TextFieldStyles,
} from "../../Refactored/StartProposalV2/styles";
import {
  EFSLoanOptions,
  EnFinLoanOptions,
  getLoanInterestOptions,
  handleEmpowerChange,
  handleInterestChange,
  handleLoanTermChange,
} from "../../Refactored/StartProposalV2/Helpers";
import {
  getLoanAmount,
  getSolarPayment,
} from "../../Refactored/StartProposalV2/Calculations";

const OtherLoanFields = ({
  otherValues,
  setOtherValues,
  finnancialPartner,
}) => {
  const onChangeDownPayment = (e) => {
    let currRebate = otherValues.rebate ? otherValues.rebate : 0;
    const currEFSLoanAmount = otherValues.efs_bridge_loan_amount ?? 0;
    const EFS_application_fee =
      finnancialPartner === "EFS" || finnancialPartner === "EFSBridge"
        ? 150
        : 0;
    let newLoanAmount = getLoanAmount(otherValues, {}, false).toFixed(2);

    setOtherValues({
      ...otherValues,
      loanAmount: newLoanAmount,
      downPayment: e.target.value,
      loanPayment:
        //Customers have an option to roll their application fee into their monthly payments, we assume that they do this for this number
        (
          (newLoanAmount + EFS_application_fee) *
          otherValues.paymentFactor
        ).toFixed(2),
    });
  };

  return (
    <TextFieldStyles
      type="number"
      onChange={(e) => onChangeDownPayment(e)}
      name={"downPayment"}
      value={otherValues.downPayment}
      label={"Down Payment"}
      autoComplete="off"
      error={!otherValues.downPayment ? true : false}
    ></TextFieldStyles>
  );
};
export const Sunlight = ({ otherValues, setOtherValues }) => {
  return (
    <>
      <FormControlStyles>
        <InputLabel> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) =>
            handleLoanTermChange(e.target.value, "Sunlight", setOtherValues)
          }
          name={"loanTerm"}
        >
          <MenuItem value={15}>15 Years</MenuItem>
          <MenuItem value={20}>20 Years</MenuItem>
          <MenuItem value={25}>25 Years</MenuItem>
        </SelectStyles>

        {getLoanInterestOptions(
          otherValues.loanTerm,
          otherValues.finnancialPartner
        ).length > 1 && (
          <FormControlStyles>
            <InputLabel>Interest Rate</InputLabel>
            <SelectStyles
              name={"interest"}
              value={otherValues.interest}
              onChange={(e) =>
                handleInterestChange(e.target.value, "Sunlight", setOtherValues)
              }
            >
              {getLoanInterestOptions(
                otherValues.loanTerm,
                otherValues.finnancialPartner
              ).map((opt, i) => (
                <MenuItem value={opt.interest} key={opt.interest + i}>
                  {opt.interest}
                </MenuItem>
              ))}
            </SelectStyles>
          </FormControlStyles>
        )}
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    </>
  );
};

export const GreenSky = ({ otherValues, setOtherValues }) => {
  return (
    <>
      <FormControlStyles>
        <InputLabel> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) =>
            handleLoanTermChange(e.target.value, "GreenSky", setOtherValues)
          }
          name={"loanTerm"}
        >
          <MenuItem value={20}>20 Years</MenuItem>
        </SelectStyles>
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    </>
    // 20 yr - 5.99 % - pmtFActor .00588
  );
};

export const Dividend = ({ otherValues, setOtherValues }) => {
  const [isKeyBank, setIsKeyBank] = useState(false);

  const onSetEmpower = () => {
    handleEmpowerChange(otherValues, setOtherValues, !isKeyBank);
    setIsKeyBank(!isKeyBank);
  };

  return (
    <>
      <FormControlStyles>
        <InputLabel> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) =>
            handleLoanTermChange(e.target.value, "Dividend", setOtherValues)
          }
          name={"loanTerm"}
        >
          <MenuItem value={12}>12 Years</MenuItem>
          <MenuItem value={20}>20 Years</MenuItem>
          <MenuItem value={25}>25 Years</MenuItem>
        </SelectStyles>
        {otherValues.loanTerm && (
          <FormControlStyles>
            <InputLabel>Interest Rate</InputLabel>

            <SelectStyles
              name={"interest"}
              value={otherValues.interest}
              onChange={(e) =>
                handleInterestChange(e.target.value, "Dividend", setOtherValues)
              }
            >
              {getLoanInterestOptions(
                otherValues.loanTerm,
                otherValues.finnancialPartner
              ).map((opt, i) => (
                <MenuItem value={opt.interest} key={opt.interest + i}>
                  {opt.interest}
                </MenuItem>
              ))}
            </SelectStyles>
          </FormControlStyles>
        )}
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    </>
    //12 yr - 4.99% interest - pmt factor 0.007467667
    //20 yr - 5.99% intereset - pmt factor 0.00549533
  );
};

export const EnFin = ({ otherValues, setOtherValues }) => {
  return (
    <>
      <FormControlStyles>
        <InputLabel key={"label-loan-term"}> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) =>
            handleLoanTermChange(e.target.value, "EnFin", setOtherValues)
          }
          name={"loanTerm"}
          error={!otherValues.loanTerm}
        >
          {EnFinLoanOptions.map((option, i) => {
            return (
              <MenuItem value={option.term} key={option.term + "-term"}>
                {option.term} Years
              </MenuItem>
            );
          })}
        </SelectStyles>
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
    </>
  );
};
//  {otherValues.loanTerm && (
//         // <FormControlLabel
//         //   control={
//         //     <Switch checked={isKeyBank} onChange={() => onSetEmpower()} />
//         //   }
//         //   label={`Key Bank ${isKeyBank ? "Y" : "N"}`}
//         // />
//         <FormControlStyles>
//           <InputLabel>Interest Rate</InputLabel>
//           {/*otherValues.loanTerm === 12 ? (
//             <SelectStyles
//               name={"interest"}
//               value={otherValues.interest}
//               onChange={onInterestChange}
//             >
//               {/* <MenuItem value={6.49}>6.49</MenuItem> */}
//               <MenuItem value={5.99}>5.99</MenuItem>
//               <MenuItem value={4.99}>4.99</MenuItem>
//             </SelectStyles>
//           ) : otherValues.loanTerm === 20 ? (
//             <SelectStyles
//               name={"keybank"}
//               value={otherValues.interest}
//               onChange={onInterestChange}
//             >
//               <MenuItem value={5.49}>5.49</MenuItem>
//               <MenuItem value={4.99}>4.99</MenuItem>
//               <MenuItem value={4.49}>4.49</MenuItem>
//               <MenuItem value={2.99}>2.99</MenuItem>
//             </SelectStyles>
//           ) : (
//             <SelectStyles
//               name={"keybank"}
//               value={otherValues.interest}
//               onChange={onInterestChange}
//             >
//               <MenuItem value={6.99}>6.99</MenuItem>
//               <MenuItem value={4.99}>4.99</MenuItem>
//               <MenuItem value={4.49}>4.49</MenuItem>
//               <MenuItem value={3.99}>3.99</MenuItem>
//               <MenuItem value={2.99}>2.99</MenuItem>
//             </SelectStyles>
//           )}

export const EFS = ({ otherValues, setOtherValues, finnancialPartner }) => {
  const onChangeEFSBridgeAmount = (e) => {
    let currRebate = otherValues.rebate ?? 0;
    let currDownPayment = otherValues.downPayment ?? 0;
    let newLoanAmount = getLoanAmount(otherValues, {}, false).toFixed(2);
    const EFS_application_fee =
      finnancialPartner === "EFS" || finnancialPartner === "EFSBridge"
        ? 150
        : 0;
    setOtherValues({
      ...otherValues,
      loanAmount: newLoanAmount,
      loanPayment: getSolarPayment(otherValues, {}, false),
      efs_bridge_loan_amount: e.target.value,
    });
  };

  const loanTerms = [
    ...new Map(EFSLoanOptions.map((item) => [item.term, item.term])).values(),
  ];

  return (
    <>
      <FormControlStyles>
        <InputLabel> Loan Term</InputLabel>
        <SelectStyles
          value={otherValues.loanTerm}
          onChange={(e) =>
            handleLoanTermChange(e.target.value, "EFS", setOtherValues)
          }
          name={"loanTerm"}
        >
          {loanTerms.map((option) => (
            <MenuItem value={option} key={option + "-term"}>
              {option} Years
            </MenuItem>
          ))}
        </SelectStyles>
        {/* </FormControlStyles>
      <FormControlStyles> */}
        {getLoanInterestOptions(
          otherValues.loanTerm,
          otherValues.finnancialPartner
        ).length > 1 && (
          <FormControlStyles>
            <InputLabel>Interest Rate</InputLabel>
            <SelectStyles
              name={"interest"}
              value={otherValues.interest}
              onChange={(e) =>
                handleInterestChange(
                  e.target.value,
                  otherValues.finnancialPartner,
                  setOtherValues
                )
              }
            >
              {getLoanInterestOptions(
                otherValues.loanTerm,
                otherValues.finnancialPartner
              ).map((opt, i) => (
                <MenuItem value={opt.interest} key={opt.interest + i}>
                  {opt.interest}
                </MenuItem>
              ))}
            </SelectStyles>
          </FormControlStyles>
        )}
      </FormControlStyles>
      <OtherLoanFields
        otherValues={otherValues}
        setOtherValues={setOtherValues}
      />
      {otherValues.finnancialPartner === "EFSBridge" && (
        <>
          <TextFieldStyles
            type="number"
            onChange={(e) => onChangeEFSBridgeAmount(e)}
            name={"efs_bridge_loan_amount"}
            value={otherValues.efs_bridge_loan_amount}
            label={"EFS Bridge Loan Amount"}
            autoComplete="off"
            error={!otherValues.efs_bridge_loan_amount ? true : false}
          />
          <TextFieldStyles
            type="number"
            onChange={(e) =>
              setOtherValues({
                ...otherValues,
                efs_balloon_payment_amount: e.target.value,
              })
            }
            name={"efs_balloon_payment_amount"}
            value={otherValues.efs_balloon_payment_amount}
            label={"EFS Balloon Payment Amount"}
            autoComplete="off"
            error={!otherValues.efs_balloon_payment_amount ? true : false}
          />
        </>
      )}
    </>
    // 20 yr - 5.99 % - pmtFActor .00588
  );
};
