import { useLazyQuery } from "@apollo/react-hooks";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Add, Close, OpenInBrowser, OpenInNew } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import { COMPLETED_PROPOSAL_SEARCH_QUERY } from "../../utils/GQL/CompletedProposalQuery";
import styled from "styled-components";
import { Context } from "../../Context/ProposalContext";
import useFetchAdditionalProposalFields from "./useFetchAdditionalProposalFields";
import { BatteryContext } from "../../Context/BatteryContext";

function LoadDataFromProposal({
  setInitialProposalData,
  initialFormValues,
  setInitialFormValues,
  allProducts,
}) {
  const { setOtherValues } = useContext(Context);
  const { setGeneracForm } = useContext(BatteryContext);

  const [fetchedId, setfetchedId] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [getProp, { loading, data, error, called }] = useLazyQuery(
    COMPLETED_PROPOSAL_SEARCH_QUERY,
    {
      variables: {
        oppnum: initialFormValues?.oppnum.toUpperCase(),
      },
    }
  );
  const fetchedProposal = useFetchAdditionalProposalFields(
    fetchedId,
    setOtherValues,
    setGeneracForm
  );

  const searchByOppnum = (e) => {
    e.preventDefault();
    setDialogOpen(true);
    getProp();
  };

  const handleSelect = (proposal) => {
    if (proposal?.id) setfetchedId(proposal?.id);
    handleClose();
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (!fetchedProposal) return;
    setOtherValues((prev) => {
      const panel = allProducts?.allPanels.find(
        (prod) => prod.name === fetchedProposal.solarPanel
      );
      const inverter = allProducts?.inverters.find(
        (prod) => prod.name === fetchedProposal.inverter
      );

      let result = {
        ...prev,
        proposalType: fetchedProposal.proposalType,
        solarPanel: fetchedProposal.solarPanel,
        panelWattage: fetchedProposal.panelWattage,
        panelInfo: panel._id,
        inverter: inverter.name,
        inverterWattage: `${inverter.pmax}`,
      };

      if (fetchedProposal.loanTerm) {
        result = {
          ...result,
          finnancialPartner: fetchedProposal.finnancialPartner,
          loanTerm: fetchedProposal.loanTerm,
          interest: fetchedProposal.interest,
        };
      }
      return result;
    });

    setInitialProposalData((prev) =>
      prev
        ? Object.keys(prev).reduce(
            (result, key) => {
              if (fetchedProposal[key] && key !== "utility")
                result[key] = fetchedProposal[key];

              return result;
            },
            { ...prev }
          )
        : { id: fetchedProposal.id }
    );

    setInitialFormValues((prev) =>
      Object.keys(prev).reduce(
        (result, key) => {
          if (fetchedProposal[key] && key !== "utility")
            result[key] = fetchedProposal[key];

          return result;
        },
        { ...prev }
      )
    );
  }, [fetchedProposal]);

  return (
    <>
      {initialFormValues?.oppnum && (
        <Button
          variant="outlined"
          startIcon={<Add />}
          style={{ width: "300px", margin: "auto" }}
          onClick={searchByOppnum}
        >
          Load Data From Proposal
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth={"lg"}
      >
        <CloseButton onClick={handleClose}>{<Close />}</CloseButton>
        <DialogTitle>{initialFormValues?.oppnum}</DialogTitle>
        <DialogContent style={{ padding: "2em" }}>
          {loading ? (
            <Typography>Finding Proposals...</Typography>
          ) : (
            <>
              {error && (
                <Typography>
                  Something went wrong: {error?.message ?? error}
                </Typography>
              )}
              {data?.completedProposals.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <Typography variant="caption">Version</Typography>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.completedProposals?.map((proposal) => (
                        <ProposalRow
                          proposal={proposal}
                          key={proposal.id}
                          onSelect={() => handleSelect(proposal)}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No previous proposals found</Typography>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const ProposalRow = ({ proposal, onSelect }) => {
  return (
    <TableRow size={"small"}>
      <TableCell size={"small"}>
        <Typography>{proposal.proposalDraftNum}</Typography>
      </TableCell>
      <TableCell>
        <Button
          size="small"
          variant="contained"
          style={{ fontSize: "12px" }}
          onClick={onSelect}
        >
          Select
        </Button>
      </TableCell>

      <TableCell size={"small"}>
        <StatsGrid>
          <p>EPC: </p>
          <p>
            {proposal.EPC?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }) ?? "--"}
          </p>
          <p>Size: </p>
          <p>{proposal.size ?? "--"}</p>
          <p>Production: </p>
          <p>{Math.round(proposal.production) ?? "--"}</p>
          <p>Consumption: </p>
          <p>{Math.round(proposal.consumption) ?? "--"}</p>
          <p>Panels: </p>
          <p>{proposal.panels ?? "--"}</p>
        </StatsGrid>
      </TableCell>
      <TableCell size={"small"}>
        <StatsGrid>
          <p>Proposal Type: </p>
          <p>{proposal.proposalType ?? "--"}</p>
          {proposal?.loanAmount && (
            <>
              <p>Loan Amount: </p>
              <p>
                {proposal.loanAmount?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                }) ?? "--"}
              </p>
            </>
          )}
          {!!proposal?.downPayment && (
            <>
              <p>Down Payment: </p>
              <p>{10 ?? "--"}</p>
            </>
          )}
        </StatsGrid>
        <a
          target="_blank"
          href={proposal.pdfUrl}
          rel="noreferrer"
          style={{ margin: "auto" }}
        >
          <Button
            variant="outlined"
            size="small"
            style={{ fontSize: "10px" }}
            startIcon={<OpenInNew />}
          >
            View
          </Button>
        </a>
      </TableCell>
    </TableRow>
  );
};

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 14px;
  column-gap: 8px;

  align-items: center;

  & > p {
    margin: 0;
    white-space: nowrap;
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

export default LoadDataFromProposal;
