import React from "react";
import { tableHeaders } from "../Home";
import ProposalTable from "../../components/ProposalTable/ProposalTable";
import DispatchProposalStart from "../../components/DIspatchProposalStart/DispatchProposalStart";
import DropProposal from "../../components/DropProposal/DropProposal";
import { useContext } from "react";
import { Context } from "../../Context/ProposalContext";

// NOT USED?
const MyProposals = ({ data, active }) => {
  const { appPermissions } = useContext(Context);

  let newTableHeaders = [...tableHeaders];
  delete newTableHeaders[6];
  newTableHeaders.push({ id: "8", label: "Drop Proposal", key: "drop" });

  const newData = data.proposalsAssigned.reduce((prev, curr) => {
    curr = {
      ...curr,
      dispatchproposal: <DispatchProposalStart data={curr} revision={false} />,
      drop: <DropProposal userId={appPermissions._id} proposal={curr} />,
    };
    prev.push(curr);
    return prev;
  }, []);

  return (
    <ProposalTable
      data={newData}
      tableHeaders={newTableHeaders}
      active={active}
    />
  );
};

export default MyProposals;
