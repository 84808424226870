import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router";

import AdminHome from "./AdminHome/AdminHome";

import UserHome from "./UserHome/UserHome";
import DispatchProposalStart from "../components/DIspatchProposalStart/DispatchProposalStart";
import {
  Context,
  initialFormVals,
  initialOtherValues,
} from "../Context/ProposalContext";

export const tableHeaders = [
  { id: "1", label: "Sales Rep", key: "RepName" },
  { id: "2", label: "Opportunity Number", key: "oppnum" },
  { id: "3", label: "Customer", key: "CustomerName" },
  { id: "4", label: "Office", key: "Office" },
  { id: "5", label: "Appointment Date", key: "AppointmentDateString" },
  { id: "6", label: "Appointment Address", key: "Address" },
  { id: "7", label: "Data Sheet Complete", key: "datasheetcomplete" },

  { id: "9", label: "Dispatch Proposal", key: "dispatchproposal" },
];

const Home = ({ data, history }) => {
  const {
    appPermissions,
    setInitialValues,

    setOtherValues,
    setInitialProposalData,
  } = useContext(Context);

  useEffect(() => {
    console.log("clearing vals");
    setInitialProposalData("");
    setInitialValues(initialFormVals);
    setOtherValues(initialOtherValues);
  }, [setInitialValues, setOtherValues, setInitialProposalData]);

  const { admin, user, permissionsList } = appPermissions;

  if (
    permissionsList.includes("HOAONLY") ||
    permissionsList.includes("IMAGESONLY")
  )
    history.push("/completed");
  if (admin || permissionsList.includes("ASSIGN")) {
    console.log("here in admin");
    return <Admin currentUser={data} />;
  } else if (user) {
    // console.log("user");
    return <User data={data} tableHeaders={tableHeaders} />;
  } else {
    //some weird issue with app permissions, forces a reload
    window.location.reload(true);
    return <p>Loading...</p>;
  }
};

export const User = ({ data }) => {
  const newData = data.proposalsAssigned.reduce((prev, curr) => {
    curr = {
      ...curr,
      datasheetcomplete: curr.datasheetcomplete ? "Y" : "N",
      dispatchproposal: <DispatchProposalStart data={curr} revision={false} />,
    };
    prev.push(curr);
    return prev;
  }, []);

  return <UserHome usersProposals={newData} />;
};

const Admin = ({ currentUser }) => {
  return <AdminHome currentUser={currentUser} />;
};

export default withRouter(Home);
