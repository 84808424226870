import React, { useContext, useEffect } from "react";
import { BatteryContext } from "../../Context/BatteryContext";
import { TextFieldStyles } from "../../Refactored/StartProposalV2/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export const BATTERY_MODELS = [{ name: "IQ Battery 5P", size: 5.0 }];

const GeneracForm = ({ proposalType }) => {
  const { generacForm, setGeneracForm } = useContext(BatteryContext);
  const { batteryModuleQty, batteryPPW, batteryRebate, batteryModel } =
    generacForm;

  const changeInput = (e) => {
    if (e.target.name === "batteryPPW") {
      let updated = {
        ...generacForm,
        batteryPPW: e.target.value,
        batteryCost:
          +e.target.value * (+batteryModuleQty * batteryModel.size) * 1000,
      };
      setGeneracForm(updated);
    } else if (e.target.name === "batteryModuleQty") {
      let updated = {
        ...generacForm,
        enphaseRebate: 0,
        batteryModuleQty: e.target.value,
        batteryCost: +batteryPPW * (+e.target.value * batteryModel.size) * 1000,
      };
      setGeneracForm(updated);
    } else {
      setGeneracForm({
        ...generacForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    //TODO: Remove me once there are multiple batteries
    setGeneracForm((prev) => ({ ...prev, batteryModel: BATTERY_MODELS[0] }));
  }, []);

  console.log(generacForm);

  return (
    <>
      <FormControl style={{ width: "45%" }}>
        <InputLabel id="battery-model-label">Battery Model</InputLabel>
        <Select
          value={batteryModel}
          labelId="battery-model-label"
          label="Battery Model"
          name="batteryModel"
          onChange={changeInput}
        >
          {BATTERY_MODELS.map((option) => (
            <MenuItem value={option} key={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextFieldStyles
        type="number"
        name={"batteryModuleQty"}
        value={batteryModuleQty}
        onChange={(e) => changeInput(e)}
        label={"Battery Quantity"}
        autoComplete="off"
      />

      <TextFieldStyles
        type="number"
        name={"batteryPPW"}
        value={batteryPPW}
        onChange={(e) => changeInput(e)}
        label={"Battery PPW"}
        autoComplete="off"
      />
      <TextFieldStyles
        type="number"
        name={"batteryRebate"}
        value={batteryRebate}
        onChange={(e) => changeInput(e)}
        label={"Battery Rebate"}
        autoComplete="off"
      />
    </>
  );
};

export default GeneracForm;
