import Axios from "axios";
import { useEffect, useState } from "react";
import { ANNUAL_CONSUMPTION_CURVE } from "./Calculations";

const DISALLOWED_LIST = ["timerequested"];

const getProposal = async (guid) => {
  try {
    const { data } = await Axios.post(
      "https://tld49hyei2.execute-api.us-east-1.amazonaws.com/latest/v1/prod/get_completed_proposal",
      { guid }
    );
    return data;
  } catch (e) {
    console.log("Error fetching old proposal", e);
  }
};

export default function useFetchAdditionalProposalFields(
  proposalId,
  setOtherValues,
  setGeneracForm
) {
  const [fetchedOldProposal, setFetchedOldProposal] = useState();
  useEffect(() => {
    if (proposalId) {
      getProposal(proposalId).then((data) => {
        setOtherValues((prev) => {
          let result = {
            ...prev,
            eminemId: data?.eminem_id
              ? ("" + data?.eminem_id).replace(/\D+/g, "")
              : "",
            monthly_consumption:
              data?.monthly_consumption?.length === 12
                ? data?.monthly_consumption
                : data?.consumption > 0
                ? ANNUAL_CONSUMPTION_CURVE.map((v) => v * data.consumption)
                : "",
            monthly_consumption:
              data?.monthly_consumption?.length === 12
                ? data?.monthly_consumption
                : data?.consumption > 0
                ? ANNUAL_CONSUMPTION_CURVE.map((v) => v * data.consumption)
                : "",
            monthly_production:
              data?.monthly_production?.length === 12
                ? data?.monthly_production
                : [],
            tsrf: data?.tsrf ?? "",
          };
          result = Object.keys(result).reduce(
            (res, key) => {
              if (!data[key] || DISALLOWED_LIST.includes(key)) return res;

              if (key === "loanTerm") {
                res = {
                  ...res,
                  finnancialPartner: data.finnancialPartner,
                  loanTerm: data.loanTerm,
                  interest: data.interest,
                  paymentFactor: data.paymentFactor,
                };
              } else {
                res[key] = data[key];
              }

              return res;
            },
            { ...result }
          );

          return result;
        });

        if (data?.generacSystem) {
          setGeneracForm((prev) => {
            const result = Object.keys(prev).reduce(
              (res, key) => {
                if (data.generacSystem[key]) {
                  res[key] = data.generacSystem[key];
                }

                if (key === "batteryModuleQty") {
                  res[key] = data.generacSystem["batteryModuleQuantity"];
                }

                if (key === "battery_make_model") {
                  console.log(data);
                }

                if (key === "batteryPPW") {
                  res[key] =
                    data.generacSystem.batteryCost /
                      (data.generacSystem.batteryModuleQuantity *
                        10.08 * //TODO: WHEN WE ADD MORE BATTERY MODELS, WE WILL NEED TO USE THE SYSTEM SIZE HERE
                        1000) ?? "";
                }
                return res;
              },
              { ...prev }
            );
            return result;
          });
        }
        data && setFetchedOldProposal(data);
      });
    }
  }, [proposalId]);

  return fetchedOldProposal;
}
