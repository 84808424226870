import React, { useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { BrowserRouter, Switch } from "react-router-dom";
import Home from "../containers/Home";
import Login from "../components/Login";
import Completed from "../containers/Completed/Completed";
import { withRouter } from "react-router";
import { authProvider } from "../Auth/authConfig";
import { AzureAD } from "react-aad-msal";
import { Header } from "../components/Header/Header";
import Landing from "../containers/Landing/Landing";
import { USER_BY_EMAIL_QUERY } from "../utils/GQL/QLQueries";
import { ALL_PRODUCTS_QUERY } from "../utils/ProductsQueries";
import Products from "../containers/Products/Products";
import { ADD_USER_MUTATION } from "../utils/QLMutations";
import { Context } from "../Context/ProposalContext";
import BatteryProposal from "../containers/BatteryProposal/BatteryProposal";
import BatteryHome from "../containers/BatteryHome/BatteryHome";
import SearchProp from "../pages/SearchProp/SearchProp";
import NewCustomModal from "../components/NewCustomModal/NewCustomModal";
import AppointmentTracker from "../containers/AppointmentTracker/StateComponent/AppointmentTracker/AppointmentTracker";

//new
import StartProposalV2 from "../Refactored/StartProposalV2/StartProposalV2";

const PrivateRoute = withRouter((props) => {
  const { component: Component } = props;
  const {
    setValue,
    userValues: { name, userName },
    setSessionActivated,
  } = useContext(Context);

  const { data, loading, error } = useQuery(USER_BY_EMAIL_QUERY, {
    variables: {
      proposalAgentEmail: userName,
    },
    pollInterval: 40000,
  });
  // eslint-disable-next-line
  const { loading: productsLoading, error: productsError } =
    useQuery(ALL_PRODUCTS_QUERY);

  const [addUser] = useMutation(ADD_USER_MUTATION, {
    variables: {
      proposalAgentName: name,
      proposalAgentEmail: userName,
      permissions: [],
    },
    update(cache, { data: { addUser: addUserData } }) {
      cache.writeQuery({
        query: USER_BY_EMAIL_QUERY,
        variables: { proposalAgentEmail: userName },
        data: { userbyemail: addUserData },
      });
    },
  });
  // if (productsLoading) return <OtherLoader />;
  // if (productsError) return "A Data Error Occured";

  return (
    <AzureAD provider={authProvider}>
      {({ accountInfo }) => {
        if (!name && accountInfo && accountInfo.account) {
          setValue({
            name: accountInfo.account.name,
            userName: accountInfo.account.userName,
            token: accountInfo.jwtIdToken,
          });
          console.log(accountInfo);
        }
        return accountInfo && accountInfo.account ? (
          <>
            <NewCustomModal />
            <Header
              setSessionActivated={setSessionActivated}
              logout={authProvider.logout}
              accountInfo={accountInfo}
            />
            <Landing
              error={error}
              loading={loading}
              data={data}
              addUser={addUser}
              Component={Component}
            />
          </>
        ) : (
          <Login login={authProvider.login} />
        );
      }}
    </AzureAD>
  );
});

const AppRouter = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/" component={Home} exact={true} />
        <PrivateRoute path="/completed" component={Completed} exact={true} />
        <PrivateRoute path="/searchprop" component={SearchProp} />
        <PrivateRoute path="/products" component={Products} exact={true} />
        <PrivateRoute path="/battery" component={BatteryHome} exact={true} />
        <PrivateRoute
          path="/appointmenttracking"
          component={AppointmentTracker}
          exact={true}
        />
        <PrivateRoute
          path="/startpropsal/:id"
          component={StartProposalV2}
          exact={true}
        />
        <PrivateRoute
          path="/batteryproposal"
          component={BatteryProposal}
          exact={true}
        />
        <PrivateRoute path="/batteryproposal/:id" component={BatteryProposal} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
